import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import crisis1 from "../../../static/img/crisis/crisis1.jpg"
import crisis2 from "../../../static/img/crisis/crisis2.jpg"
import crisis4 from "../../../static/img/crisis/crisis4.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Manejo de crisis"/>
            <Banner title="Manejo de crisis"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Perfil del cliente</h3>

                                <p>Una empresa perteneciente al sector de servicios
                                    financieros
                                    comunicó su intención de ejecutar una oferta pública de adquisición por uno de sus
                                    pares
                                    del sector,
                                    misma que fue tomada de manera negativa por el mercado, debido a que el market cap
                                    de la
                                    empresa
                                    oferente es inferior a la del objetivo de adquisición; por lo que hay profundas
                                    dudas
                                    acerca del
                                    financiamiento de la operación, así como de las sinergias y economías de escala que
                                    se
                                    pudieran generar,
                                    razón por la cual la acción de la compañía adquirente ha disminuido en doble dígito
                                    durante una sola
                                    sesión de remate.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={crisis1} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={crisis2} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandato</h3>

                                <p>Por su experimentado equipo en sucesos corporativos y
                                    operaciones
                                    de M&A, IRStrat es seleccionado como asesor del proceso de adquisición, a través de
                                    la
                                    contratación de
                                    su solución IRStrat II (“Mensaje Estratégico”), con el mandato de ampliar el
                                    alcance de
                                    las
                                    comunicaciones del cliente y perfeccionar el mensaje de la adquisición; con la
                                    finalidad
                                    convencer al
                                    mercado de que esta última ayudará a fortalecer el negocio del cliente a largo
                                    plazo, disipando, de esta forma, el escepticismo que afectó negativamente la valuación y operatividad de
                                    las
                                    acciones de
                                    nuestro cliente.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solución</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Estrategia
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat conduce ágilmente una encuesta
                                            de gran alcance
                                            para levantar el sentimiento del mercado sobre la transacción. Con la
                                            información recabada, se
                                            ejecuta un plan de reposicionamiento y comunicación exprés. Posteriormente,
                                            se define una agenda
                                            de PR extensiva y se prepara un plan de divulgación, que comprende una serie
                                            de mensajes
                                            cuidadosamente modelados para informar correctamente el valor a ser generado
                                            por la transacción
                                            (una vez revisado a profundidad el due dilligence de la transacción con la
                                            gerencia de la
                                            empresa). Asimismo, IRStrat ayuda en el proceso de designación de los
                                            funcionarios responsables
                                            de brindar entrevistas y comunicar noticias oportunas y estratégicas sobre
                                            la transacción.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Táctica
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>Se ejecuta una agenda ágil y extensiva que
                                            incluye una exposición
                                            mediática eficaz hacia los principales medios financieros (TV, impresos y
                                            radio). Se conducen
                                            presentaciones vis à vis con las áreas de análisis y promoción de las
                                            principales casas de bolsa e
                                            instituciones financieras, así como con los administradores de portafolios
                                            de los principales
                                            fondos de inversión y de pensiones que posean posición de la acción de
                                            nuestro cliente.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Resultados
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Nuestro cliente revierte la caída en su acción,
                                            registrando un alza de
                                            15% con respecto al precio de cierre anterior al anuncio público de la
                                            oferta de adquisición,
                                            gracias a la explicación detallada de los beneficios de la operación y de
                                            sus fuentes de fondeo.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={crisis4} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
